import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from "../../components/seo";
import PGSideBar from '../../components/pg-side-bar';

import '../../styles/cus.css';

const PatentGuide_4Steps = () => {
    const seo = {
        metaDesc: 'How to Start Your Utility Patent Application Process in 4 Steps - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="How to Start Your Utility Patent Application Process in 4 Steps - Inventiv.org" canonical='/patent-guide' seo={seo} />
            <div class="sub-banner main-banner">
                <div class="container">
                    <div class="breadcrumb-area" style={{ top: 30 + '%' }}>
                        <h1 className='fw-special-title'>&nbsp;</h1>
                        <h1 className='title-banner'>How to Start Your Utility Patent Application Process in 4 Steps</h1>
                    </div>
                </div>
            </div>

            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container ">
                    <div className='row'>
                        <div className='col-md-8 col-sm-12'>
                            <figure className='text-center'>
                                <img width="300" height="300"
                                    src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
                                    data-src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
                                    class="vc_single_image-img attachment-medium lazyloaded" alt="" decoding="async"
                                    data-srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w"
                                    data-sizes="(max-width: 300px) 100vw, 300px" data-pagespeed-url-hash="2358126750" sizes="(max-width: 300px) 100vw, 300px"
                                    srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w" />
                            </figure>
                            <div class="wpb_wrapper">
                                <h2>How to Start Your Utility Patent Application Process in 4 Steps</h2>
                                <p><span style={{ fontWeight: 400 }}>The process for applying for a patent is known as patent prosecution.&nbsp;
                                    Here are some things you should do before starting the application process.</span></p>
                                <h3>Step One:&nbsp;<b>Document the invention process.</b></h3>
                                <p><span style={{ fontWeight: 400 }}>While you can use electronic records as inventor’s notebooks, the golden
                                    standard is still a bound notebook with consecutively numbered pages.&nbsp; You use this
                                    inventor’s notebook to detail when you envisioned your invention and when you started
                                    constructing it.&nbsp;&nbsp;</span></p>
                                <p><span style={{ fontWeight: 400 }}>This is important because inventor’s notebooks, along with corroborating
                                    evidence, have been central in proving who was first to invent. Even with the first-to-file
                                    system, inventor notebooks are still important to show derivation.&nbsp;</span></p>
                                <p><span style={{ fontWeight: 400 }}>A typical lab notebook will include such items as:</span></p>
                                <ul>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>a description of the invention;</span>
                                    </li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>testing and results;</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>prior art research; and</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>drawings of the invention.</span></li>
                                </ul>
                                <p><span style={{ fontWeight: 400 }}>After each entry you make in the notebook, be sure to insert the date you
                                    made the entry and sign it.&nbsp; Entries should be chronological, and made as soon as you
                                    complete each step of the invention process.&nbsp;&nbsp;</span></p>
                                <p><span style={{ fontWeight: 400 }}>Separate print-outs, photos and other documents should be permanently pasted
                                    into the notebook.&nbsp; You should also write a caption describing what the document is, along
                                    with dating and signing it.</span></p>
                                <p><span style={{ fontWeight: 400 }}>Additionally, be sure to have a trusted witness sign each entry you make in
                                    the notebook.&nbsp; Again, this is helpful if someone brings suit.&nbsp; Though you only need
                                    one witness, it’s preferable to have at least two just in case one is unavailable to testify
                                    later at trial if that’s necessary.&nbsp; The witness should be knowledgeable about the
                                    invention’s field because he/she must testify that he/she understands what’s written in the
                                    notebook.&nbsp;&nbsp;</span></p>
                                <p><span style={{ fontWeight: 400 }}>It is recommended that witnesses also sign a confidentiality agreement prior
                                    to reading your notebook.&nbsp; This may help protect the trade secrets in your notebook as long
                                    as you meet the statutory requirements, which were discussed previously, to prove the notebook
                                    contains trade secrets.</span></p>
                                <p><span style={{ fontWeight: 400 }}>In the Congressional Record dated March 3, 2011, Congress said inventors can
                                    file a provisional patent application to bypass some of the issues associated with the
                                    inventor’s notebook. This application has the same function as the inventor’s notebook because
                                    it requires an invention’s description, including what the invention is and how it works. It is
                                    also a dated government document so one cannot easily tamper with it. Furthermore, this does
                                    away with expensive discovery if a claim is later challenged.</span></p>
                                <h3>Step Two: Search for prior art</h3>
                                <p><span style={{ fontWeight: 400 }}>A patent search can be beneficial at various stages of the inventing process.
                                    An initial patent search at the time of conception and formulation of an idea helps you assess
                                    the depth and extent others took in solving similar problems. Moreover, for brainstorming, the
                                    patent search can help refine your idea and stimulate further inventive thinking. Furthermore, a
                                    comprehensive awareness of the prior art can aid you when you draft claims.&nbsp; You’ll learn
                                    how to write claims with the appropriate scope so you can obtain patents that are as broad as
                                    possible, yet defensible.</span></p>
                                <p><span style={{ fontWeight: 400 }}>To search for prior art, sometimes known as a patentability search, you can
                                    <a href="http://inventi.org">use a professional patent researcher</a>&nbsp;, or you can go
                                    directly to the USPTO office in Alexandria, the USPTO’s patent and trademark depository
                                    libraries nationwide, or visit online databases. Click to learn how to do patent search yourself
                                    the right way</span></p>
                                <p><strong>Note:</strong></p>
                                <p><span style={{ fontWeight: 400 }}>Though you may believe your search to be good, whether you do it yourself or
                                    hire a professional, it is probably not as in depth as what a patent examiner will do.&nbsp; For
                                    example, that’s because:</span></p>
                                <ul>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>you won’t find pending applications that
                                        aren’t published,</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>there’s erroneous patent classification in
                                        the databases that can’t be discovered via keyword searches,</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>recently issued patents won’t be in the
                                        system immediately, or</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>U.S. patent searches won’t bring up
                                        foreign patents.</span></li>
                                </ul>
                                <h3><b>Step Three: How to deal with multiple inventors</b></h3>
                                <p><span style={{ fontWeight: 400 }}>When you have co-inventors or joint inventors, all of you share patent
                                    ownership.&nbsp; To qualify as such, each inventor must’ve contributed to an idea that became a
                                    part of at least one of the invention’s claims.&nbsp; You must have a concept of the invention
                                    as a complete and definite whole, as well.&nbsp;&nbsp;</span></p>
                                <p><span style={{ fontWeight: 400 }}>You are not a co-inventor if you come late to the project and the others have
                                    already formed a complete, though scientifically uncertain, concept of the invention.&nbsp; If
                                    that’s the case, you only help clear up the scientific uncertainty.</span></p>
                                <ol>
                                    <li>
                                        <h4><b> how to prove joint inventors</b></h4>
                                    </li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>&nbsp;&nbsp;</span> <span style={{ fontWeight: 400 }}>Each inventor should have
                                    his/her own inventor’s notebook.&nbsp; You don’t need to all work together, at the same time or
                                    at the same place, however.</span></p>
                                <ol start="2">
                                    <li>
                                        <h4><b> legal issues&nbsp;</b><b></b></h4>
                                    </li>
                                </ol>
                                <h5><b></b><b>a. patent application fails to list an inventor</b></h5>
                                <p><span style={{ fontWeight: 400 }}>If the patent applicant does not list an inventor intentionally, the USPTO
                                    may strike down any resulting patent rights or limit what rights the applicant can
                                    enforce.&nbsp;&nbsp;</span></p>
                                <h5><b>b. dividing revenue</b></h5>
                                <p><span style={{ fontWeight: 400 }}>It is up to the inventors to decide how to split any joint revenue.&nbsp;
                                    There are some basic rules, however.&nbsp;&nbsp;</span></p>
                                <ul>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>If trying to assign </span><span
                                        style={{ fontWeight: 400 }}>all </span><span style={{ fontWeight: 400 }}>patent
                                            rights somewhere, </span><span style={{ fontWeight: 400 }}>all</span><span
                                                style={{ fontWeight: 400 }}> joint owners must agree on the assignment.&nbsp; Hence, one
                                            inventor can’t assign </span><span style={{ fontWeight: 400 }}>all </span><span
                                                style={{ fontWeight: 400 }}>patent rights by him/herself.</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Any one inventor can use, make or sell the
                                        invention without permission from the others or paying them.</span></li>
                                </ul>
                                <ol start="3">
                                    <li>
                                        <h4><b> how to protect inventors</b></h4>
                                    </li>
                                </ol>
                                <h5><b></b><b>a. Consultant’s Agreement</b></h5>
                                <p><span style={{ fontWeight: 400 }}>If someone is just assisting the inventor, he/she should sign a Consultant’s
                                    Agreement.&nbsp; This agreement means the person assigns all of his/her rights to the invention
                                    to the inventor.&nbsp;&nbsp;</span></p>
                                <h5>b.&nbsp;Joint Ownership Agreement (JOA)</h5>
                                <p><span style={{ fontWeight: 400 }}>A JOA can prevent problems resulting from shared finances, such as who owns
                                    which financial shares to the invention.&nbsp; A JOA typically:</span></p>
                                <ul>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Prevents one inventor from exploiting the
                                        patent without the others’ consent.&nbsp; Though a majority of the inventors can act if
                                        all of them cannot reach a unanimous agreement;</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Includes a method for resolving
                                        disputes;</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Includes details about how to divide any
                                        revenue, which is usually proportional to expenses incurred by each inventor; and</span>
                                    </li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Guides the course of action when one
                                        inventor wants to manufacture and sell the invention, including how to divide
                                        royalties.</span></li>
                                </ul>
                                <h3>Step Four: &nbsp;<b>How to deal with employers.</b></h3>
                                <p><span style={{ fontWeight: 400 }}>If inventing is part of your job, then you may be obligated to transfer your
                                    invention rights to your employer.&nbsp; To determine if this is your situation, look at
                                    whether:</span></p>
                                <ul>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>You signed an employment agreement prior
                                        to the invention.&nbsp; Sometimes this is known as a preinvention agreement.&nbsp; The
                                        agreement usually contains a provision in which you surrendered your invention
                                        rights.&nbsp; This can include inventions you create in your spare time or at home so
                                        read this carefully;</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>You were hired or employed specifically to
                                        invent.&nbsp; Under U.S. Supreme Court precedent, your employer doesn’t even need to
                                        have you sign a separate agreement for this;&nbsp;</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Your employer acquired shop right, which
                                        is a nontransferable, limited patent right to use the invention for his/her own use and
                                        for business purposes only.&nbsp; To claim this, you must’ve used your employer’s
                                        resources, such as time or supplies, to create the invention; or</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>You work for a college or university, and
                                        you’ve granted the school rights to all inventions you make when you use its
                                        resources.&nbsp;&nbsp;</span></li>
                                </ul>
                                <p>&nbsp;</p>
                                <ol>
                                    <li><b> what your employer may require</b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>If you are associated with an employer, he/she may require you to divulge all
                                    of your inventions so he/she will know what’s worth patenting or assigning.&nbsp; Your employer
                                    may also have you sign a power of attorney so he/she can file a patent without your help or if
                                    you leave the company.&nbsp; Furthermore, your employer may request that you testify or keep
                                    good records of your invention process even after you leave.</span></p>
                                <p>&nbsp;</p>
                                <ol start="2">
                                    <li><b> what you may get in return</b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>In return for your invention, an employer may reward you via a salary bonus,
                                    he/she may set up a subsidiary entity that is partially owned by you so you can also gain from
                                    your invention, or your employer might give you a certain percentage of your invention’s
                                    royalties.</span></p>
                                <p>&nbsp;</p>
                                <ol start="3">
                                    <li><b> if your employer isn’t interested</b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>If you’ve told your employer about your invention and he/she isn’t interested
                                    in it, you can apply for a release.&nbsp; A release is a document that has the employer return
                                    the invention back to you.&nbsp;&nbsp;</span></p>
                                <p>&nbsp;</p>
                            </div>
                            <div className='mt-5'>
                                <p>
                                <a href='/inventor-resources' className='btn btn-warning rounded-pill px-5'>Download Provisional Patent Assistant Now!</a>
                                </p>
                            </div>
                        </div>

                        <div className='col-md-4 col-sm-12'>
                            <PGSideBar></PGSideBar>
                        </div>
                    </div>

                </div>
            </section>

        </Layout>
    )
};

export default PatentGuide_4Steps;